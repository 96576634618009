<template>
    <supportLayout>
        <div class="p-bug-reports full_page">
            <v-container>
                <div class="coming_soon">
                    <h3>In Development</h3>
                </div>
        </v-container>
        </div>
    </supportLayout>
</template>

<script>
import supportLayout from "@/layouts/supportLayout.vue";

export default {
    name: 'bug-reports',
    components: {
        supportLayout
    }
}
</script>
